.table-dropdown-button {
  > * {
    .icon {
      vertical-align: bottom;
    }

    .fa {
      color: $grey-light;
      margin-right: 0.5rem;
    }
  }

  .edit-button {
    &:hover {
      .fa {
        color: $primary;
      }
    }
  }

  .delete-button {
    border: none;
    background-color: white;
    display: block;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0.375rem 1rem;
    padding-right: 3rem;
    position: relative;
    white-space: nowrap;
    width: 100%;
    text-decoration: none;
    text-align: inherit;
    color: #4a4a4a;

    /* stylelint-disable-next-line no-descending-specificity */
    .fa {
      color: $grey-light;
      margin-right: 0.5rem;
    }

    &:hover {
      background-color: whitesmoke;
      color: #0a0a0a;

      .fa {
        color: $danger;
      }
    }

    .icon {
      vertical-align: bottom;
    }
  }
}
