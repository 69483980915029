.agroanalisa-navbar {
  .navbar {
    background: rgb(25, 118, 210);
    border-radius: 0;
    border: 0;
    padding: 0 10px 0 10px;

    .p-menubar-button {
      .pi-bars {
        color: white;
      }
    }
  }

  .p-menubar .p-menubar-root-list {
    .navbar-title {
      font-size: 2em;
    }

    .p-menuitem {
      .p-menuitem-link {
        .p-submenu-icon,
        .p-menuitem-icon,
        .p-menuitem-text,
        .p-menuitem-text .p-submenu-icon {
          color: white;
        }

        &:not(.p-disabled):hover {
          .p-submenu-icon,
          .p-menuitem-icon,
          .p-menuitem-text {
            color: white;
          }
        }
      }

      &.p-menuitem-active {
        .p-menuitem-link {
          &:not(.p-disabled):hover {
            .p-submenu-icon,
            .p-menuitem-icon,
            .p-menuitem-text {
              color: white;
            }
          }

          .p-submenu-icon,
          .p-menuitem-icon,
          .p-menuitem-text,
          .p-menuitem-text .p-submenu-icon {
            color: white;
          }
        }
      }
    }

    .p-submenu-list {
      .logout {
        .p-menuitem-link {
          &:not(.p-disabled):hover {
            .p-menuitem-icon,
            .p-menuitem-text {
              color: black;
            }
          }

          .p-menuitem-text,
          .p-menuitem-icon {
            color: black;
          }
        }
      }
    }

    .name-user,
    .icon-close,
    .navbar-title {
      .p-menuitem-link {
        .p-menuitem-text,
        .p-menuitem-icon {
          @media screen and (max-width: 960px) {
            color: black;
          }
        }
      }
    }
  }
}
