.field.has-floating-label {
  position: relative;
  height: 58px;

  .label {
    pointer-events: none;
    position: absolute;
    margin: -8px 0 0;
    z-index: 1;
    font-weight: 400;
    color: $grey;
    top: 18px;
    left: $control-padding-horizontal;
    transition: all 100ms $easing;
    background: #fff;
    padding: 0 5px;
    line-height: 1;
    cursor: text;

    &.is-active {
      top: 2px;
      font-size: $size-7;
    }
  }

  .control {
    .input {
      &:-webkit-autofill {
        box-shadow: 0 0 0 30px #fff inset;
      }
    }
  }
}
