.select-chart-type {
  .dropdown {
    width: 100%;
  }
}

.dropdown-align-center {
  width: 1.5em;
  text-align: center;
}
