.navbar-side {
  z-index: 1;

  .modal {
    z-index: $modal-z - 5;

    &.is-active {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .menu {
    background-color: #fff;
    width: $navbar-side-width;

    a {
      align-items: center;
      display: flex;
      min-height: 3em;
      font-size: 1.25em;
      font-weight: 500;
      padding: 0 1em;
    }
  }
}
