.dashboard {
  .dashboard-title-content {
    margin-bottom: 0;
    padding: 1em;
  }

  .dashboard-title {
    font-size: 2em;
  }
}
