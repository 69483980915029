.page-title {
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  text-align: center;

  .title {
    font-weight: 300;
    font-size: 3em;
  }
}

.borderless {
  border: 0;
}

.backgroundless {
  background-color: transparent;
}
