.modal {
  // General
  .modal-background {
    z-index: -1;
  }

  overflow: visible;

  .shadow {
    flex: 1;
    height: 100%;
  }

  .modal-card-head {
    .delete {
      background: transparent;
      top: -10px;
      left: 10px;

      &::before,
      &::after {
        background-color: $grey;
      }
    }
  }

  &.right-sidebar-modal {
    align-items: flex-end;
  }

  &.left-sidebar-modal {
    align-items: flex-start;
  }

  // Background animations
  .background {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }

    &-exit-done {
      display: none;
    }
  }

  // fade-left
  &.fade-left > .shadow {
    box-shadow: 2px 6px 12px $border-hover;
    margin-right: $modal-side-shadows;
  }

  .fade-left {
    &-enter {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    &-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }

    &-exit-done {
      display: none;
    }
  }

  // fade-right
  &.fade-right > .shadow {
    box-shadow: -2px 6px 12px $border-hover;
    margin-right: $modal-side-shadows;
  }

  .fade-right {
    &-enter {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    &-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }
  }

  // fade-center
  &.fade-center {
    & > .shadow {
      box-shadow: 0 0 24px $grey-darker;
      overflow: inherit;
      flex: none;
      border-radius: $radius-large;

      & > * {
        border-radius: $radius-large;
      }

      height: auto;
    }
  }

  .fade-center {
    border-radius: 30px;
    $modal-animation-duration: 300ms;

    &-enter {
      opacity: 0;
      transform: scale(0.75);
    }

    &-enter-active {
      opacity: 1;
      transform: scale(1);
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transform: scale(0.75);
      transition: opacity $modal-animation-duration, transform $modal-animation-duration;
    }
  }
}
