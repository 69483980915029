.notification-button {
  background-color: white;

  .notification-content {
    color: black;

    .notification-title {
      color: black;
    }

    .notification-message {
      color: black;
    }

    .timer {
      .timer-filler {
        background-color: $grey-light;
      }
    }
  }
}
