.customers {
  .proprieties-button {
    &:hover {
      .fa {
        color: #4c994c !important;
      }
    }
  }

  .contracts-button {
    &:hover {
      .fa {
        color: #ffac33 !important;
      }
    }
  }
}

.create-edit-page {
  .dropdown-input {
    margin-right: 25px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 45%;
  }

  .text-input-container {
    margin: 20px;
    margin-left: 0;

    .text-input {
      width: 95%;
    }
  }

  .number-input,
  .date-input {
    width: 100%;
  }
}

.create-edit-page-customer-farm {
  .dropdown-input {
    width: 100%;
  }

  .field.has-floating-label {
    height: auto;
  }
}

.create-edit-page-customer-contract {
  .dropdown-input {
    width: 100%;
  }

  .field.has-floating-label {
    height: auto;
  }
}
