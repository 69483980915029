html,
body {
  display: flex;
  flex-flow: column;
  min-height: 100%;
  flex: 1;
  font-size: 14px;
  background-color: $white;
  overflow: auto;
}

#root {
  display: flex;
  flex-flow: column;
  flex: 1;
}

*:focus {
  outline: none;
}

.button {
  &.is-link:focus:not(:active) {
    box-shadow: unset;
  }
}

.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: none;
}

.checkbox {
  input {
    margin-right: 0.5rem;
    margin-top: 0.8rem;
  }
}

.uuid-column {
  width: 38ch;
}

.small-column {
  width: 18ch;
}

.large-column {
  width: 40ch;
}

.options-column {
  width: 6ch;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-emptymessage > td {
  text-align: center;
}

.p-datatable-tbody td {
  vertical-align: middle;
}
