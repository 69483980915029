.dropdown {
  &-content {
    *> {
      @extend .dropdown-item;
    }

    display: inline-block;
    text-align: left;
  }

  .dropdown-menu {
    min-width: 0;
  }
}
