@import 'bulma';

// bulma variables
$modal-background-background-color: rgba(10, 10, 10, 0.555);
$primary: #1976d2;

$navbar-background-color: $primary;
$navbar-height: 4.5rem;
$navbar-side-width: 19em;
$menu-item-active-background-color: rgba($primary, 0.12);
$menu-item-active-color: $primary;
$menu-item-radius: 0;

// modal variables
$modal-side-shadows: get_shadow_space(2, 12) * 1px;
$modal-animation-duration: 400ms;

$panel-header-color: #f1f1f1;
