.loader-modal {
  .loader-wrapper {
    .loader {
      height: 80px;
      width: 80px;
      border-left-color: $primary;
      border-bottom-color: $primary;
      border-left-width: 0.3em;
    }
  }

  &.fade-center {
    .shadow {
      box-shadow: none;
    }
  }

  z-index: $modal-z + 1;
}
