.agroanalisa-navbar {
  .pages {
    max-height: calc(100vh - #{$navbar-height});
    display: flex;

    & > :not(.navbar-side) {
      flex: 1;
      padding: $modal-side-shadows;
      overflow: auto;
    }

    & > *:first-child {
      display: inline-flex;
      max-width: $navbar-side-width;
    }
  }
}
